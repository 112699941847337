/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1HttpGet } from './V1HttpGet';
import {
    V1HttpGetFromJSON,
    V1HttpGetFromJSONTyped,
    V1HttpGetToJSON,
    V1HttpGetToJSONTyped,
} from './V1HttpGet';

/**
 * 
 * @export
 * @interface V1Probe
 */
export interface V1Probe {
    /**
     * 
     * @type {V1HttpGet}
     * @memberof V1Probe
     */
    httpGet?: V1HttpGet;
}

/**
 * Check if a given object implements the V1Probe interface.
 */
export function instanceOfV1Probe(value: object): value is V1Probe {
    return true;
}

export function V1ProbeFromJSON(json: any): V1Probe {
    return V1ProbeFromJSONTyped(json, false);
}

export function V1ProbeFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Probe {
    if (json == null) {
        return json;
    }
    return {
        
        'httpGet': json['httpGet'] == null ? undefined : V1HttpGetFromJSON(json['httpGet']),
    };
}

  export function V1ProbeToJSON(json: any): V1Probe {
      return V1ProbeToJSONTyped(json, false);
  }

  export function V1ProbeToJSONTyped(value?: V1Probe | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'httpGet': V1HttpGetToJSON(value['httpGet']),
    };
}

