/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppSpecV1Alpha1Service
 */
export interface AppSpecV1Alpha1Service {
    /**
     * 
     * @type {number}
     * @memberof AppSpecV1Alpha1Service
     */
    port?: number;
}

/**
 * Check if a given object implements the AppSpecV1Alpha1Service interface.
 */
export function instanceOfAppSpecV1Alpha1Service(value: object): value is AppSpecV1Alpha1Service {
    return true;
}

export function AppSpecV1Alpha1ServiceFromJSON(json: any): AppSpecV1Alpha1Service {
    return AppSpecV1Alpha1ServiceFromJSONTyped(json, false);
}

export function AppSpecV1Alpha1ServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppSpecV1Alpha1Service {
    if (json == null) {
        return json;
    }
    return {
        
        'port': json['port'] == null ? undefined : json['port'],
    };
}

  export function AppSpecV1Alpha1ServiceToJSON(json: any): AppSpecV1Alpha1Service {
      return AppSpecV1Alpha1ServiceToJSONTyped(json, false);
  }

  export function AppSpecV1Alpha1ServiceToJSONTyped(value?: AppSpecV1Alpha1Service | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'port': value['port'],
    };
}

