/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Commonv1Value } from './Commonv1Value';
import {
    Commonv1ValueFromJSON,
    Commonv1ValueFromJSONTyped,
    Commonv1ValueToJSON,
    Commonv1ValueToJSONTyped,
} from './Commonv1Value';

/**
 * 
 * @export
 * @interface V1ImagePullSecrets
 */
export interface V1ImagePullSecrets {
    /**
     * 
     * @type {Commonv1Value}
     * @memberof V1ImagePullSecrets
     */
    username?: Commonv1Value;
    /**
     * 
     * @type {Commonv1Value}
     * @memberof V1ImagePullSecrets
     */
    password?: Commonv1Value;
}

/**
 * Check if a given object implements the V1ImagePullSecrets interface.
 */
export function instanceOfV1ImagePullSecrets(value: object): value is V1ImagePullSecrets {
    return true;
}

export function V1ImagePullSecretsFromJSON(json: any): V1ImagePullSecrets {
    return V1ImagePullSecretsFromJSONTyped(json, false);
}

export function V1ImagePullSecretsFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ImagePullSecrets {
    if (json == null) {
        return json;
    }
    return {
        
        'username': json['username'] == null ? undefined : Commonv1ValueFromJSON(json['username']),
        'password': json['password'] == null ? undefined : Commonv1ValueFromJSON(json['password']),
    };
}

  export function V1ImagePullSecretsToJSON(json: any): V1ImagePullSecrets {
      return V1ImagePullSecretsToJSONTyped(json, false);
  }

  export function V1ImagePullSecretsToJSONTyped(value?: V1ImagePullSecrets | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'username': Commonv1ValueToJSON(value['username']),
        'password': Commonv1ValueToJSON(value['password']),
    };
}

