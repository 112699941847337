/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ListRequestQueuesResponseQueueReason = {
    QueueReasonUnspecified: 'QueueReasonUnspecified',
    QueueReasonNotDispatch: 'QueueReasonNotDispatch',
    QueueReasonWorkerBusy: 'QueueReasonWorkerBusy',
    QueueReasonSessionWorkerBusy: 'QueueReasonSessionWorkerBusy'
} as const;
export type ListRequestQueuesResponseQueueReason = typeof ListRequestQueuesResponseQueueReason[keyof typeof ListRequestQueuesResponseQueueReason];


export function instanceOfListRequestQueuesResponseQueueReason(value: any): boolean {
    for (const key in ListRequestQueuesResponseQueueReason) {
        if (Object.prototype.hasOwnProperty.call(ListRequestQueuesResponseQueueReason, key)) {
            if (ListRequestQueuesResponseQueueReason[key as keyof typeof ListRequestQueuesResponseQueueReason] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ListRequestQueuesResponseQueueReasonFromJSON(json: any): ListRequestQueuesResponseQueueReason {
    return ListRequestQueuesResponseQueueReasonFromJSONTyped(json, false);
}

export function ListRequestQueuesResponseQueueReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListRequestQueuesResponseQueueReason {
    return json as ListRequestQueuesResponseQueueReason;
}

export function ListRequestQueuesResponseQueueReasonToJSON(value?: ListRequestQueuesResponseQueueReason | null): any {
    return value as any;
}

export function ListRequestQueuesResponseQueueReasonToJSONTyped(value: any, ignoreDiscriminator: boolean): ListRequestQueuesResponseQueueReason {
    return value as ListRequestQueuesResponseQueueReason;
}

