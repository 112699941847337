/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Commonv1Value } from './Commonv1Value';
import {
    Commonv1ValueFromJSON,
    Commonv1ValueFromJSONTyped,
    Commonv1ValueToJSON,
    Commonv1ValueToJSONTyped,
} from './Commonv1Value';
import type { AutoscalerDecorators } from './AutoscalerDecorators';
import {
    AutoscalerDecoratorsFromJSON,
    AutoscalerDecoratorsFromJSONTyped,
    AutoscalerDecoratorsToJSON,
    AutoscalerDecoratorsToJSONTyped,
} from './AutoscalerDecorators';

/**
 * 
 * @export
 * @interface AutoscalerBuiltinScaler
 */
export interface AutoscalerBuiltinScaler {
    /**
     * 
     * @type {string}
     * @memberof AutoscalerBuiltinScaler
     */
    name?: string;
    /**
     * 
     * @type {{ [key: string]: Commonv1Value; }}
     * @memberof AutoscalerBuiltinScaler
     */
    arguments?: { [key: string]: Commonv1Value; };
    /**
     * 
     * @type {AutoscalerDecorators}
     * @memberof AutoscalerBuiltinScaler
     */
    decorators?: AutoscalerDecorators;
}

/**
 * Check if a given object implements the AutoscalerBuiltinScaler interface.
 */
export function instanceOfAutoscalerBuiltinScaler(value: object): value is AutoscalerBuiltinScaler {
    return true;
}

export function AutoscalerBuiltinScalerFromJSON(json: any): AutoscalerBuiltinScaler {
    return AutoscalerBuiltinScalerFromJSONTyped(json, false);
}

export function AutoscalerBuiltinScalerFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoscalerBuiltinScaler {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'arguments': json['arguments'] == null ? undefined : (mapValues(json['arguments'], Commonv1ValueFromJSON)),
        'decorators': json['decorators'] == null ? undefined : AutoscalerDecoratorsFromJSON(json['decorators']),
    };
}

  export function AutoscalerBuiltinScalerToJSON(json: any): AutoscalerBuiltinScaler {
      return AutoscalerBuiltinScalerToJSONTyped(json, false);
  }

  export function AutoscalerBuiltinScalerToJSONTyped(value?: AutoscalerBuiltinScaler | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'arguments': value['arguments'] == null ? undefined : (mapValues(value['arguments'], Commonv1ValueToJSON)),
        'decorators': AutoscalerDecoratorsToJSON(value['decorators']),
    };
}

