/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { VolumeItem } from './VolumeItem';
import {
    VolumeItemFromJSON,
    VolumeItemFromJSONTyped,
    VolumeItemToJSON,
    VolumeItemToJSONTyped,
} from './VolumeItem';

/**
 * 
 * @export
 * @interface VolumeSecretItem
 */
export interface VolumeSecretItem {
    /**
     * 
     * @type {string}
     * @memberof VolumeSecretItem
     */
    secretName?: string;
    /**
     * 
     * @type {Array<VolumeItem>}
     * @memberof VolumeSecretItem
     */
    items?: Array<VolumeItem>;
}

/**
 * Check if a given object implements the VolumeSecretItem interface.
 */
export function instanceOfVolumeSecretItem(value: object): value is VolumeSecretItem {
    return true;
}

export function VolumeSecretItemFromJSON(json: any): VolumeSecretItem {
    return VolumeSecretItemFromJSONTyped(json, false);
}

export function VolumeSecretItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): VolumeSecretItem {
    if (json == null) {
        return json;
    }
    return {
        
        'secretName': json['secretName'] == null ? undefined : json['secretName'],
        'items': json['items'] == null ? undefined : ((json['items'] as Array<any>).map(VolumeItemFromJSON)),
    };
}

  export function VolumeSecretItemToJSON(json: any): VolumeSecretItem {
      return VolumeSecretItemToJSONTyped(json, false);
  }

  export function VolumeSecretItemToJSONTyped(value?: VolumeSecretItem | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'secretName': value['secretName'],
        'items': value['items'] == null ? undefined : ((value['items'] as Array<any>).map(VolumeItemToJSON)),
    };
}

