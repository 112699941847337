/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppServiceUpdateAppAuxiliaryUserBody
 */
export interface AppServiceUpdateAppAuxiliaryUserBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof AppServiceUpdateAppAuxiliaryUserBody
     */
    users?: Array<string>;
}

/**
 * Check if a given object implements the AppServiceUpdateAppAuxiliaryUserBody interface.
 */
export function instanceOfAppServiceUpdateAppAuxiliaryUserBody(value: object): value is AppServiceUpdateAppAuxiliaryUserBody {
    return true;
}

export function AppServiceUpdateAppAuxiliaryUserBodyFromJSON(json: any): AppServiceUpdateAppAuxiliaryUserBody {
    return AppServiceUpdateAppAuxiliaryUserBodyFromJSONTyped(json, false);
}

export function AppServiceUpdateAppAuxiliaryUserBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppServiceUpdateAppAuxiliaryUserBody {
    if (json == null) {
        return json;
    }
    return {
        
        'users': json['users'] == null ? undefined : json['users'],
    };
}

  export function AppServiceUpdateAppAuxiliaryUserBodyToJSON(json: any): AppServiceUpdateAppAuxiliaryUserBody {
      return AppServiceUpdateAppAuxiliaryUserBodyToJSONTyped(json, false);
  }

  export function AppServiceUpdateAppAuxiliaryUserBodyToJSONTyped(value?: AppServiceUpdateAppAuxiliaryUserBody | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'users': value['users'],
    };
}

