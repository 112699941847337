/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1ImagePullSecrets } from './V1ImagePullSecrets';
import {
    V1ImagePullSecretsFromJSON,
    V1ImagePullSecretsFromJSONTyped,
    V1ImagePullSecretsToJSON,
    V1ImagePullSecretsToJSONTyped,
} from './V1ImagePullSecrets';
import type { V1Resource } from './V1Resource';
import {
    V1ResourceFromJSON,
    V1ResourceFromJSONTyped,
    V1ResourceToJSON,
    V1ResourceToJSONTyped,
} from './V1Resource';
import type { V1LifeCycle } from './V1LifeCycle';
import {
    V1LifeCycleFromJSON,
    V1LifeCycleFromJSONTyped,
    V1LifeCycleToJSON,
    V1LifeCycleToJSONTyped,
} from './V1LifeCycle';
import type { V1Volume } from './V1Volume';
import {
    V1VolumeFromJSON,
    V1VolumeFromJSONTyped,
    V1VolumeToJSON,
    V1VolumeToJSONTyped,
} from './V1Volume';
import type { V1Environment } from './V1Environment';
import {
    V1EnvironmentFromJSON,
    V1EnvironmentFromJSONTyped,
    V1EnvironmentToJSON,
    V1EnvironmentToJSONTyped,
} from './V1Environment';
import type { V1Probe } from './V1Probe';
import {
    V1ProbeFromJSON,
    V1ProbeFromJSONTyped,
    V1ProbeToJSON,
    V1ProbeToJSONTyped,
} from './V1Probe';
import type { AppSpecV1Alpha1Service } from './AppSpecV1Alpha1Service';
import {
    AppSpecV1Alpha1ServiceFromJSON,
    AppSpecV1Alpha1ServiceFromJSONTyped,
    AppSpecV1Alpha1ServiceToJSON,
    AppSpecV1Alpha1ServiceToJSONTyped,
} from './AppSpecV1Alpha1Service';
import type { V1Autoscaler } from './V1Autoscaler';
import {
    V1AutoscalerFromJSON,
    V1AutoscalerFromJSONTyped,
    V1AutoscalerToJSON,
    V1AutoscalerToJSONTyped,
} from './V1Autoscaler';
import type { AppSpecV1Alpha1RouteAuthType } from './AppSpecV1Alpha1RouteAuthType';
import {
    AppSpecV1Alpha1RouteAuthTypeFromJSON,
    AppSpecV1Alpha1RouteAuthTypeFromJSONTyped,
    AppSpecV1Alpha1RouteAuthTypeToJSON,
    AppSpecV1Alpha1RouteAuthTypeToJSONTyped,
} from './AppSpecV1Alpha1RouteAuthType';
import type { V1VolumeMount } from './V1VolumeMount';
import {
    V1VolumeMountFromJSON,
    V1VolumeMountFromJSONTyped,
    V1VolumeMountToJSON,
    V1VolumeMountToJSONTyped,
} from './V1VolumeMount';

/**
 * 
 * @export
 * @interface V1AppSpecV1Alpha1
 */
export interface V1AppSpecV1Alpha1 {
    /**
     * 
     * @type {string}
     * @memberof V1AppSpecV1Alpha1
     */
    image?: string;
    /**
     * 
     * @type {V1ImagePullSecrets}
     * @memberof V1AppSpecV1Alpha1
     */
    imagePullSecrets?: V1ImagePullSecrets;
    /**
     * 
     * @type {Array<V1VolumeMount>}
     * @memberof V1AppSpecV1Alpha1
     */
    volumeMounts?: Array<V1VolumeMount>;
    /**
     * 
     * @type {Array<V1Environment>}
     * @memberof V1AppSpecV1Alpha1
     */
    env?: Array<V1Environment>;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1AppSpecV1Alpha1
     */
    command?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof V1AppSpecV1Alpha1
     */
    port?: number;
    /**
     * 
     * @type {V1Probe}
     * @memberof V1AppSpecV1Alpha1
     */
    readinessProbe?: V1Probe;
    /**
     * 
     * @type {Array<V1Volume>}
     * @memberof V1AppSpecV1Alpha1
     */
    volumes?: Array<V1Volume>;
    /**
     * 
     * @type {V1Resource}
     * @memberof V1AppSpecV1Alpha1
     */
    resource: V1Resource;
    /**
     * 
     * @type {AppSpecV1Alpha1RouteAuthType}
     * @memberof V1AppSpecV1Alpha1
     */
    routeAuthType?: AppSpecV1Alpha1RouteAuthType;
    /**
     * 
     * @type {string}
     * @memberof V1AppSpecV1Alpha1
     */
    routePublicKey?: string;
    /**
     * 
     * @type {number}
     * @memberof V1AppSpecV1Alpha1
     */
    requestQuota?: number;
    /**
     * 
     * @type {V1Autoscaler}
     * @memberof V1AppSpecV1Alpha1
     */
    autoscaler?: V1Autoscaler;
    /**
     * 
     * @type {Array<AppSpecV1Alpha1Service>}
     * @memberof V1AppSpecV1Alpha1
     */
    services?: Array<AppSpecV1Alpha1Service>;
    /**
     * 
     * @type {V1LifeCycle}
     * @memberof V1AppSpecV1Alpha1
     */
    lifeCycle?: V1LifeCycle;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1AppSpecV1Alpha1
     */
    auxiliaryUsers?: Array<string>;
}



/**
 * Check if a given object implements the V1AppSpecV1Alpha1 interface.
 */
export function instanceOfV1AppSpecV1Alpha1(value: object): value is V1AppSpecV1Alpha1 {
    if (!('resource' in value) || value['resource'] === undefined) return false;
    return true;
}

export function V1AppSpecV1Alpha1FromJSON(json: any): V1AppSpecV1Alpha1 {
    return V1AppSpecV1Alpha1FromJSONTyped(json, false);
}

export function V1AppSpecV1Alpha1FromJSONTyped(json: any, ignoreDiscriminator: boolean): V1AppSpecV1Alpha1 {
    if (json == null) {
        return json;
    }
    return {
        
        'image': json['image'] == null ? undefined : json['image'],
        'imagePullSecrets': json['imagePullSecrets'] == null ? undefined : V1ImagePullSecretsFromJSON(json['imagePullSecrets']),
        'volumeMounts': json['volumeMounts'] == null ? undefined : ((json['volumeMounts'] as Array<any>).map(V1VolumeMountFromJSON)),
        'env': json['env'] == null ? undefined : ((json['env'] as Array<any>).map(V1EnvironmentFromJSON)),
        'command': json['command'] == null ? undefined : json['command'],
        'port': json['port'] == null ? undefined : json['port'],
        'readinessProbe': json['readinessProbe'] == null ? undefined : V1ProbeFromJSON(json['readinessProbe']),
        'volumes': json['volumes'] == null ? undefined : ((json['volumes'] as Array<any>).map(V1VolumeFromJSON)),
        'resource': V1ResourceFromJSON(json['resource']),
        'routeAuthType': json['routeAuthType'] == null ? undefined : AppSpecV1Alpha1RouteAuthTypeFromJSON(json['routeAuthType']),
        'routePublicKey': json['routePublicKey'] == null ? undefined : json['routePublicKey'],
        'requestQuota': json['requestQuota'] == null ? undefined : json['requestQuota'],
        'autoscaler': json['autoscaler'] == null ? undefined : V1AutoscalerFromJSON(json['autoscaler']),
        'services': json['services'] == null ? undefined : ((json['services'] as Array<any>).map(AppSpecV1Alpha1ServiceFromJSON)),
        'lifeCycle': json['lifeCycle'] == null ? undefined : V1LifeCycleFromJSON(json['lifeCycle']),
        'auxiliaryUsers': json['auxiliaryUsers'] == null ? undefined : json['auxiliaryUsers'],
    };
}

  export function V1AppSpecV1Alpha1ToJSON(json: any): V1AppSpecV1Alpha1 {
      return V1AppSpecV1Alpha1ToJSONTyped(json, false);
  }

  export function V1AppSpecV1Alpha1ToJSONTyped(value?: V1AppSpecV1Alpha1 | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'image': value['image'],
        'imagePullSecrets': V1ImagePullSecretsToJSON(value['imagePullSecrets']),
        'volumeMounts': value['volumeMounts'] == null ? undefined : ((value['volumeMounts'] as Array<any>).map(V1VolumeMountToJSON)),
        'env': value['env'] == null ? undefined : ((value['env'] as Array<any>).map(V1EnvironmentToJSON)),
        'command': value['command'],
        'port': value['port'],
        'readinessProbe': V1ProbeToJSON(value['readinessProbe']),
        'volumes': value['volumes'] == null ? undefined : ((value['volumes'] as Array<any>).map(V1VolumeToJSON)),
        'resource': V1ResourceToJSON(value['resource']),
        'routeAuthType': AppSpecV1Alpha1RouteAuthTypeToJSON(value['routeAuthType']),
        'routePublicKey': value['routePublicKey'],
        'requestQuota': value['requestQuota'],
        'autoscaler': V1AutoscalerToJSON(value['autoscaler']),
        'services': value['services'] == null ? undefined : ((value['services'] as Array<any>).map(AppSpecV1Alpha1ServiceToJSON)),
        'lifeCycle': V1LifeCycleToJSON(value['lifeCycle']),
        'auxiliaryUsers': value['auxiliaryUsers'],
    };
}

