/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1ResourceFilter } from './V1ResourceFilter';
import {
    V1ResourceFilterFromJSON,
    V1ResourceFilterFromJSONTyped,
    V1ResourceFilterToJSON,
    V1ResourceFilterToJSONTyped,
} from './V1ResourceFilter';

/**
 * 
 * @export
 * @interface V1Resource
 */
export interface V1Resource {
    /**
     * 
     * @type {number}
     * @memberof V1Resource
     */
    cpu: number;
    /**
     * 
     * @type {string}
     * @memberof V1Resource
     */
    memory: string;
    /**
     * 
     * @type {number}
     * @memberof V1Resource
     */
    gpu?: number;
    /**
     * 
     * @type {V1ResourceFilter}
     * @memberof V1Resource
     */
    filters?: V1ResourceFilter;
}

/**
 * Check if a given object implements the V1Resource interface.
 */
export function instanceOfV1Resource(value: object): value is V1Resource {
    if (!('cpu' in value) || value['cpu'] === undefined) return false;
    if (!('memory' in value) || value['memory'] === undefined) return false;
    return true;
}

export function V1ResourceFromJSON(json: any): V1Resource {
    return V1ResourceFromJSONTyped(json, false);
}

export function V1ResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Resource {
    if (json == null) {
        return json;
    }
    return {
        
        'cpu': json['cpu'],
        'memory': json['memory'],
        'gpu': json['gpu'] == null ? undefined : json['gpu'],
        'filters': json['filters'] == null ? undefined : V1ResourceFilterFromJSON(json['filters']),
    };
}

  export function V1ResourceToJSON(json: any): V1Resource {
      return V1ResourceToJSONTyped(json, false);
  }

  export function V1ResourceToJSONTyped(value?: V1Resource | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'cpu': value['cpu'],
        'memory': value['memory'],
        'gpu': value['gpu'],
        'filters': V1ResourceFilterToJSON(value['filters']),
    };
}

