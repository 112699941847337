/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1HttpGet
 */
export interface V1HttpGet {
    /**
     * 
     * @type {string}
     * @memberof V1HttpGet
     */
    path?: string;
}

/**
 * Check if a given object implements the V1HttpGet interface.
 */
export function instanceOfV1HttpGet(value: object): value is V1HttpGet {
    return true;
}

export function V1HttpGetFromJSON(json: any): V1HttpGet {
    return V1HttpGetFromJSONTyped(json, false);
}

export function V1HttpGetFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1HttpGet {
    if (json == null) {
        return json;
    }
    return {
        
        'path': json['path'] == null ? undefined : json['path'],
    };
}

  export function V1HttpGetToJSON(json: any): V1HttpGet {
      return V1HttpGetToJSONTyped(json, false);
  }

  export function V1HttpGetToJSONTyped(value?: V1HttpGet | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'path': value['path'],
    };
}

