/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1ImagePullSecrets } from './V1ImagePullSecrets';
import {
    V1ImagePullSecretsFromJSON,
    V1ImagePullSecretsFromJSONTyped,
    V1ImagePullSecretsToJSON,
    V1ImagePullSecretsToJSONTyped,
} from './V1ImagePullSecrets';
import type { V1Volume } from './V1Volume';
import {
    V1VolumeFromJSON,
    V1VolumeFromJSONTyped,
    V1VolumeToJSON,
    V1VolumeToJSONTyped,
} from './V1Volume';
import type { V1EntryPath } from './V1EntryPath';
import {
    V1EntryPathFromJSON,
    V1EntryPathFromJSONTyped,
    V1EntryPathToJSON,
    V1EntryPathToJSONTyped,
} from './V1EntryPath';

/**
 * 
 * @export
 * @interface AutoscalerContainerScaler
 */
export interface AutoscalerContainerScaler {
    /**
     * 
     * @type {string}
     * @memberof AutoscalerContainerScaler
     */
    image?: string;
    /**
     * 
     * @type {V1ImagePullSecrets}
     * @memberof AutoscalerContainerScaler
     */
    imagePullSecrets?: V1ImagePullSecrets;
    /**
     * 
     * @type {Array<string>}
     * @memberof AutoscalerContainerScaler
     */
    command?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AutoscalerContainerScaler
     */
    port?: number;
    /**
     * 
     * @type {V1EntryPath}
     * @memberof AutoscalerContainerScaler
     */
    entryPath?: V1EntryPath;
    /**
     * 
     * @type {Array<V1Volume>}
     * @memberof AutoscalerContainerScaler
     */
    volumes?: Array<V1Volume>;
}

/**
 * Check if a given object implements the AutoscalerContainerScaler interface.
 */
export function instanceOfAutoscalerContainerScaler(value: object): value is AutoscalerContainerScaler {
    return true;
}

export function AutoscalerContainerScalerFromJSON(json: any): AutoscalerContainerScaler {
    return AutoscalerContainerScalerFromJSONTyped(json, false);
}

export function AutoscalerContainerScalerFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutoscalerContainerScaler {
    if (json == null) {
        return json;
    }
    return {
        
        'image': json['image'] == null ? undefined : json['image'],
        'imagePullSecrets': json['imagePullSecrets'] == null ? undefined : V1ImagePullSecretsFromJSON(json['imagePullSecrets']),
        'command': json['command'] == null ? undefined : json['command'],
        'port': json['port'] == null ? undefined : json['port'],
        'entryPath': json['entryPath'] == null ? undefined : V1EntryPathFromJSON(json['entryPath']),
        'volumes': json['volumes'] == null ? undefined : ((json['volumes'] as Array<any>).map(V1VolumeFromJSON)),
    };
}

  export function AutoscalerContainerScalerToJSON(json: any): AutoscalerContainerScaler {
      return AutoscalerContainerScalerToJSONTyped(json, false);
  }

  export function AutoscalerContainerScalerToJSONTyped(value?: AutoscalerContainerScaler | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'image': value['image'],
        'imagePullSecrets': V1ImagePullSecretsToJSON(value['imagePullSecrets']),
        'command': value['command'],
        'port': value['port'],
        'entryPath': V1EntryPathToJSON(value['entryPath']),
        'volumes': value['volumes'] == null ? undefined : ((value['volumes'] as Array<any>).map(V1VolumeToJSON)),
    };
}

