/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1CPUConstraint
 */
export interface V1CPUConstraint {
    /**
     * 
     * @type {Array<string>}
     * @memberof V1CPUConstraint
     */
    platforms?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof V1CPUConstraint
     */
    models?: Array<string>;
}

/**
 * Check if a given object implements the V1CPUConstraint interface.
 */
export function instanceOfV1CPUConstraint(value: object): value is V1CPUConstraint {
    return true;
}

export function V1CPUConstraintFromJSON(json: any): V1CPUConstraint {
    return V1CPUConstraintFromJSONTyped(json, false);
}

export function V1CPUConstraintFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1CPUConstraint {
    if (json == null) {
        return json;
    }
    return {
        
        'platforms': json['platforms'] == null ? undefined : json['platforms'],
        'models': json['models'] == null ? undefined : json['models'],
    };
}

  export function V1CPUConstraintToJSON(json: any): V1CPUConstraint {
      return V1CPUConstraintToJSONTyped(json, false);
  }

  export function V1CPUConstraintToJSONTyped(value?: V1CPUConstraint | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'platforms': value['platforms'],
        'models': value['models'],
    };
}

