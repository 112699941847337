/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1HttpPost } from './V1HttpPost';
import {
    V1HttpPostFromJSON,
    V1HttpPostFromJSONTyped,
    V1HttpPostToJSON,
    V1HttpPostToJSONTyped,
} from './V1HttpPost';

/**
 * 
 * @export
 * @interface V1EntryPath
 */
export interface V1EntryPath {
    /**
     * 
     * @type {V1HttpPost}
     * @memberof V1EntryPath
     */
    httpPost?: V1HttpPost;
}

/**
 * Check if a given object implements the V1EntryPath interface.
 */
export function instanceOfV1EntryPath(value: object): value is V1EntryPath {
    return true;
}

export function V1EntryPathFromJSON(json: any): V1EntryPath {
    return V1EntryPathFromJSONTyped(json, false);
}

export function V1EntryPathFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1EntryPath {
    if (json == null) {
        return json;
    }
    return {
        
        'httpPost': json['httpPost'] == null ? undefined : V1HttpPostFromJSON(json['httpPost']),
    };
}

  export function V1EntryPathToJSON(json: any): V1EntryPath {
      return V1EntryPathToJSONTyped(json, false);
  }

  export function V1EntryPathToJSONTyped(value?: V1EntryPath | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'httpPost': V1HttpPostToJSON(value['httpPost']),
    };
}

