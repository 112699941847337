/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1HttpGet } from './V1HttpGet';
import {
    V1HttpGetFromJSON,
    V1HttpGetFromJSONTyped,
    V1HttpGetToJSON,
    V1HttpGetToJSONTyped,
} from './V1HttpGet';
import type { V1Exec } from './V1Exec';
import {
    V1ExecFromJSON,
    V1ExecFromJSONTyped,
    V1ExecToJSON,
    V1ExecToJSONTyped,
} from './V1Exec';
import type { V1HttpPost } from './V1HttpPost';
import {
    V1HttpPostFromJSON,
    V1HttpPostFromJSONTyped,
    V1HttpPostToJSON,
    V1HttpPostToJSONTyped,
} from './V1HttpPost';

/**
 * 
 * @export
 * @interface V1Action
 */
export interface V1Action {
    /**
     * 
     * @type {V1HttpGet}
     * @memberof V1Action
     */
    httpGet?: V1HttpGet;
    /**
     * 
     * @type {V1HttpPost}
     * @memberof V1Action
     */
    httpPost?: V1HttpPost;
    /**
     * 
     * @type {V1Exec}
     * @memberof V1Action
     */
    exec?: V1Exec;
}

/**
 * Check if a given object implements the V1Action interface.
 */
export function instanceOfV1Action(value: object): value is V1Action {
    return true;
}

export function V1ActionFromJSON(json: any): V1Action {
    return V1ActionFromJSONTyped(json, false);
}

export function V1ActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Action {
    if (json == null) {
        return json;
    }
    return {
        
        'httpGet': json['httpGet'] == null ? undefined : V1HttpGetFromJSON(json['httpGet']),
        'httpPost': json['httpPost'] == null ? undefined : V1HttpPostFromJSON(json['httpPost']),
        'exec': json['exec'] == null ? undefined : V1ExecFromJSON(json['exec']),
    };
}

  export function V1ActionToJSON(json: any): V1Action {
      return V1ActionToJSONTyped(json, false);
  }

  export function V1ActionToJSONTyped(value?: V1Action | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'httpGet': V1HttpGetToJSON(value['httpGet']),
        'httpPost': V1HttpPostToJSON(value['httpPost']),
        'exec': V1ExecToJSON(value['exec']),
    };
}

