/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AutoscalerThirdPartyScaler } from './AutoscalerThirdPartyScaler';
import {
    AutoscalerThirdPartyScalerFromJSON,
    AutoscalerThirdPartyScalerFromJSONTyped,
    AutoscalerThirdPartyScalerToJSON,
    AutoscalerThirdPartyScalerToJSONTyped,
} from './AutoscalerThirdPartyScaler';
import type { AutoscalerContainerScaler } from './AutoscalerContainerScaler';
import {
    AutoscalerContainerScalerFromJSON,
    AutoscalerContainerScalerFromJSONTyped,
    AutoscalerContainerScalerToJSON,
    AutoscalerContainerScalerToJSONTyped,
} from './AutoscalerContainerScaler';
import type { AutoscalerBuiltinScaler } from './AutoscalerBuiltinScaler';
import {
    AutoscalerBuiltinScalerFromJSON,
    AutoscalerBuiltinScalerFromJSONTyped,
    AutoscalerBuiltinScalerToJSON,
    AutoscalerBuiltinScalerToJSONTyped,
} from './AutoscalerBuiltinScaler';

/**
 * 
 * @export
 * @interface V1Autoscaler
 */
export interface V1Autoscaler {
    /**
     * 
     * @type {string}
     * @memberof V1Autoscaler
     */
    scheduler?: string;
    /**
     * 
     * @type {AutoscalerBuiltinScaler}
     * @memberof V1Autoscaler
     */
    builtin?: AutoscalerBuiltinScaler;
    /**
     * 
     * @type {AutoscalerThirdPartyScaler}
     * @memberof V1Autoscaler
     */
    thirdParty?: AutoscalerThirdPartyScaler;
    /**
     * 
     * @type {AutoscalerContainerScaler}
     * @memberof V1Autoscaler
     */
    container?: AutoscalerContainerScaler;
}

/**
 * Check if a given object implements the V1Autoscaler interface.
 */
export function instanceOfV1Autoscaler(value: object): value is V1Autoscaler {
    return true;
}

export function V1AutoscalerFromJSON(json: any): V1Autoscaler {
    return V1AutoscalerFromJSONTyped(json, false);
}

export function V1AutoscalerFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1Autoscaler {
    if (json == null) {
        return json;
    }
    return {
        
        'scheduler': json['scheduler'] == null ? undefined : json['scheduler'],
        'builtin': json['builtin'] == null ? undefined : AutoscalerBuiltinScalerFromJSON(json['builtin']),
        'thirdParty': json['thirdParty'] == null ? undefined : AutoscalerThirdPartyScalerFromJSON(json['thirdParty']),
        'container': json['container'] == null ? undefined : AutoscalerContainerScalerFromJSON(json['container']),
    };
}

  export function V1AutoscalerToJSON(json: any): V1Autoscaler {
      return V1AutoscalerToJSONTyped(json, false);
  }

  export function V1AutoscalerToJSONTyped(value?: V1Autoscaler | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'scheduler': value['scheduler'],
        'builtin': AutoscalerBuiltinScalerToJSON(value['builtin']),
        'thirdParty': AutoscalerThirdPartyScalerToJSON(value['thirdParty']),
        'container': AutoscalerContainerScalerToJSON(value['container']),
    };
}

