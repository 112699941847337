import { Router } from "@remix-run/router/dist/router";
import { createBrowserRouter, Navigate } from "react-router-dom";
import React, { ComponentType, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "@/pages/ErrorPages";

type ComponentImport = () => Promise<{ default: ComponentType<any> }>;

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = (
    componentImport: ComponentImport,
    componentName: string,
): (() => Promise<{ default: React.ComponentType<any> }>) => {
    return () =>
        new Promise((resolve, reject) => {
            // check if the window has already been refreshed
            const hasRefreshed = JSON.parse(
                window.sessionStorage.getItem(`retry-${componentName}-refreshed`) ||
                "false",
            );
            // try to import the component
            componentImport()
                .then((component) => {
                    window.sessionStorage.setItem(
                        `retry-${componentName}-refreshed`,
                        "false",
                    ); // success so reset the refresh
                    resolve(component);
                })
                .catch((error) => {
                    if (!hasRefreshed) {
                        // not been refreshed yet
                        window.sessionStorage.setItem(
                            `retry-${componentName}-refreshed`,
                            "true",
                        ); // we are now going to refresh
                        return window.location.reload(); // refresh the page
                    }
                    reject(error); // Default error behaviour as already tried refresh
                });
        });
};

const App = React.lazy(lazyRetry(() => import("../App"), "App"));
const Login = React.lazy(lazyRetry(() => import("@/pages/Login"), "Login"));
const LoginCallback = React.lazy(
    lazyRetry(() => import("@/pages/LoginCallback"), "LoginCallback"),
);
const Dashboard = React.lazy(
    lazyRetry(() => import("@/pages/Dashboard"), "Dashboard"),
);
const Token = React.lazy(lazyRetry(() => import("@/pages/Token"), "Token"));
const Secrets = React.lazy(
    lazyRetry(() => import("@/pages/Secrets"), "Secrets"),
);
const SecretsCreate = React.lazy(
    lazyRetry(() => import("@/pages/Secrets/Create"), "SecretsCreate"),
);
const ConfigMap = React.lazy(
    lazyRetry(() => import("@/pages/ConfigMap"), "ConfigMap"),
);
const ConfigMapsCreate = React.lazy(
    lazyRetry(() => import("@/pages/ConfigMap/Create"), "ConfigMapsCreate"),
);
const Billing = React.lazy(
    lazyRetry(() => import("@/pages/Billing"), "Billing"),
);
const Storage = React.lazy(
    lazyRetry(() => import("@/pages/Storage"), "Storage"),
);
const Profile = React.lazy(
    lazyRetry(() => import("@/pages/Profile"), "Profile"),
);
const ExpenseBill = React.lazy(
    lazyRetry(() => import("@/pages/Billing/ExpenseBill"), "ExpenseBill"),
);
const Credit = React.lazy(
    lazyRetry(() => import("@/pages/Billing/Credit"), "Credit"),
);
const Application = React.lazy(
    lazyRetry(() => import("@/pages/Application"), "Application"),
);
const AppWorker = React.lazy(
    lazyRetry(() => import("@/pages/Application/AppWorker"), "AppWorker"),
);
const RequestStatistics = React.lazy(
    lazyRetry(
        () => import("@/pages/Application/RequestStatistics"),
        "RequestStatistics",
    ),
);
const StatisticsDetail = React.lazy(
    lazyRetry(
        () => import("@/pages/Application/StatisticsDetail"),
        "StatisticsDetail",
    ),
);
const AppList = React.lazy(
    lazyRetry(() => import("@/pages/Application/AppList"), "AppList"),
);
const AppEvents = React.lazy(
    lazyRetry(() => import("@/pages/Application/AppEvents"), "AppEvents"),
);
const Transactions = React.lazy(
    lazyRetry(() => import("@/pages/Billing/Transactions"), "Transactions"),
);
const Charge = React.lazy(
    lazyRetry(() => import("@/pages/Billing/Charge"), "Charge"),
);
const QrCode = React.lazy(
    lazyRetry(() => import("@/pages/Billing/QrCode"), "QrCode"),
);
const PayCallback = React.lazy(
    lazyRetry(() => import("@/pages/Billing/PayCallback"), "PayCallback"),
);
const Price = React.lazy(
    lazyRetry(() => import("@/pages/Home/Price"), "Price"),
);
const AllLogs = React.lazy(
    lazyRetry(() => import("@/pages/Loggers/AllLogs"), "AllLogs"),
);
const FAQ = React.lazy(lazyRetry(() => import("@/pages/Home/FAQ"), "FAQ"));
const Service = React.lazy(
    lazyRetry(() => import("@/pages/Home/Service"), "Service"),
);
const Privacy = React.lazy(
    lazyRetry(() => import("@/pages/Home/Privacy"), "Privacy"),
);
const Loggers = React.lazy(
    lazyRetry(() => import("@/pages/Loggers"), "Loggers"),
);
const Overview = React.lazy(
    lazyRetry(() => import("@/pages/Overview"), "Overview"),
);
const ErrorPage403 = React.lazy(
    lazyRetry(() => import("@/pages/ErrorPages/403"), "ErrorPage403"),
);
const ErrorPage404 = React.lazy(
    lazyRetry(() => import("@/pages/ErrorPages/404"), "ErrorPage404"),
);
const ErrorPage500 = React.lazy(
    lazyRetry(() => import("@/pages/ErrorPages/500"), "ErrorPage500"),
);

const env = process.env.REACT_APP_ENV;

const Loading = () => (
    <div className="flex h-screen items-center justify-center">
        {
            localStorage.getItem("theme") === "light" ? (
                <svg
                    className="-ml-1 mr-3 h-5 w-5 animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="#0064FA"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="#0064FA"
                        stroke=""
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>) : (
                <svg
                    className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                    ></circle>
                    <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            )
        }

    </div>
);

const router: Router = createBrowserRouter(
    [
        {
            path: "/",
            element: (
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<Loading />}>
                        <App />
                    </Suspense>
                </ErrorBoundary>
            ),
        },
        {
            path: "/price",
            element: (
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<Loading />}>
                        <Price />
                    </Suspense>
                </ErrorBoundary>
            ),
        },
        {
            path: "/FAQ",
            element: (
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<Loading />}>
                        <FAQ />
                    </Suspense>
                </ErrorBoundary>
            ),
        },
        {
            path: "/Service",
            element: (
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<Loading />}>
                        <Service />
                    </Suspense>
                </ErrorBoundary>
            ),
        },
        {
            path: "/Privacy",
            element: (
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<Loading />}>
                        <Privacy />
                    </Suspense>
                </ErrorBoundary>
            ),
        },
        {
            path: "/login",
            element: (
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    {" "}
                    <Suspense fallback={<Loading />}>
                        <Login />
                    </Suspense>
                </ErrorBoundary>
            ),
        },
        {
            path: "/dashboard",
            element: (
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<Loading />}>
                        <Dashboard />
                    </Suspense>
                </ErrorBoundary>
            ),
            children: [
                {
                    path: "overview",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <Overview />
                        </Suspense>
                    ),
                },
                {
                    path: "",
                    element: <Navigate to="overview" />,
                },
                {
                    path: "apps",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <Application />
                        </Suspense>
                    ),
                    children: [
                        {
                            path: "appList/:tabStatus",
                            element: (
                                <Suspense fallback={<Loading />}>
                                    <AppList />
                                </Suspense>
                            ),
                        },
                        {
                            path: "",
                            element: <Navigate to="appList/STATUS_DEPLOYED" />,
                        },
                    ],
                },
                {
                    path: "apps/appWorker/:appName/:namespace",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <AppWorker />
                        </Suspense>
                    ),
                },
                {
                    path: "apps/requestStatistics/:appName/:namespace",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <RequestStatistics />
                        </Suspense>
                    ),
                },
                {
                    path: "apps/statisticsDetail/:appName/:namespace",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <StatisticsDetail />
                        </Suspense>
                    ),
                },
                {
                    path: "apps/appEvents/:appName/:namespace",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <AppEvents />
                        </Suspense>
                    ),
                },
                {
                    path: "logs",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <Loggers />
                        </Suspense>
                    ),
                },
                {
                    path: "logs/:appName/:appId/:workerId",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <AllLogs />
                        </Suspense>
                    ),
                },
                {
                    path: "storage",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <Storage />
                        </Suspense>
                    ),
                },
                {
                    path: "secrets",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <Secrets />
                        </Suspense>
                    ),
                },
                {
                    path: "secrets/create",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <SecretsCreate />
                        </Suspense>
                    ),
                },
                {
                    path: "configMap",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <ConfigMap />
                        </Suspense>
                    ),
                },
                {
                    path: "configMap/create",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <ConfigMapsCreate />
                        </Suspense>
                    ),
                },
                {
                    path: "profile",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <Profile />
                        </Suspense>
                    ),
                },
                {
                    path: "billing",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <Billing />
                        </Suspense>
                    ),
                },
                {
                    path: "billing/payCallback",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <PayCallback />
                        </Suspense>
                    ),
                },
                {
                    path: "billing/expenseBill",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <ExpenseBill />
                        </Suspense>
                    ),
                },
                {
                    path: "billing/credit",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <Credit />
                        </Suspense>
                    ),
                },
                {
                    path: "billing/transactions",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <Transactions />
                        </Suspense>
                    ),
                },
                {
                    path: "billing/charge",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <Charge />
                        </Suspense>
                    ),
                },
                {
                    path: "billing/charge/qrcode",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <QrCode />
                        </Suspense>
                    ),
                },
                {
                    path: "token",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <Token />
                        </Suspense>
                    ),
                },
                {
                    path: "403",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <ErrorPage403 />
                        </Suspense>
                    ),
                },
                {
                    path: "404",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <ErrorPage404 />
                        </Suspense>
                    ),
                },
                {
                    path: "500",
                    element: (
                        <Suspense fallback={<Loading />}>
                            <ErrorPage500 />
                        </Suspense>
                    ),
                },
            ],
        },
        {
            path: "/auth/callback",
            element: (
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <Suspense fallback={<Loading />}>
                        <LoginCallback />
                    </Suspense>
                </ErrorBoundary>
            ),
        },
    ],
    { basename: env === "prod" ? "" : "/everai-office-web" },
);

export default router;
