/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface V1VolumeMount
 */
export interface V1VolumeMount {
    /**
     * 
     * @type {string}
     * @memberof V1VolumeMount
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof V1VolumeMount
     */
    mountPath?: string;
    /**
     * 
     * @type {boolean}
     * @memberof V1VolumeMount
     */
    readOnly?: boolean;
}

/**
 * Check if a given object implements the V1VolumeMount interface.
 */
export function instanceOfV1VolumeMount(value: object): value is V1VolumeMount {
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function V1VolumeMountFromJSON(json: any): V1VolumeMount {
    return V1VolumeMountFromJSONTyped(json, false);
}

export function V1VolumeMountFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1VolumeMount {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'mountPath': json['mountPath'] == null ? undefined : json['mountPath'],
        'readOnly': json['readOnly'] == null ? undefined : json['readOnly'],
    };
}

  export function V1VolumeMountToJSON(json: any): V1VolumeMount {
      return V1VolumeMountToJSONTyped(json, false);
  }

  export function V1VolumeMountToJSONTyped(value?: V1VolumeMount | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'mountPath': value['mountPath'],
        'readOnly': value['readOnly'],
    };
}

