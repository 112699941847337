/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ListRequestQueuesResponseQueueReason } from './ListRequestQueuesResponseQueueReason';
import {
    ListRequestQueuesResponseQueueReasonFromJSON,
    ListRequestQueuesResponseQueueReasonFromJSONTyped,
    ListRequestQueuesResponseQueueReasonToJSON,
    ListRequestQueuesResponseQueueReasonToJSONTyped,
} from './ListRequestQueuesResponseQueueReason';

/**
 * 
 * @export
 * @interface V1ListRequestQueuesResponseRequestQueue
 */
export interface V1ListRequestQueuesResponseRequestQueue {
    /**
     * 
     * @type {number}
     * @memberof V1ListRequestQueuesResponseRequestQueue
     */
    index?: number;
    /**
     * 
     * @type {ListRequestQueuesResponseQueueReason}
     * @memberof V1ListRequestQueuesResponseRequestQueue
     */
    reason?: ListRequestQueuesResponseQueueReason;
    /**
     * 
     * @type {Date}
     * @memberof V1ListRequestQueuesResponseRequestQueue
     */
    createAt?: Date;
}



/**
 * Check if a given object implements the V1ListRequestQueuesResponseRequestQueue interface.
 */
export function instanceOfV1ListRequestQueuesResponseRequestQueue(value: object): value is V1ListRequestQueuesResponseRequestQueue {
    return true;
}

export function V1ListRequestQueuesResponseRequestQueueFromJSON(json: any): V1ListRequestQueuesResponseRequestQueue {
    return V1ListRequestQueuesResponseRequestQueueFromJSONTyped(json, false);
}

export function V1ListRequestQueuesResponseRequestQueueFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ListRequestQueuesResponseRequestQueue {
    if (json == null) {
        return json;
    }
    return {
        
        'index': json['index'] == null ? undefined : json['index'],
        'reason': json['reason'] == null ? undefined : ListRequestQueuesResponseQueueReasonFromJSON(json['reason']),
        'createAt': json['createAt'] == null ? undefined : (new Date(json['createAt'])),
    };
}

  export function V1ListRequestQueuesResponseRequestQueueToJSON(json: any): V1ListRequestQueuesResponseRequestQueue {
      return V1ListRequestQueuesResponseRequestQueueToJSONTyped(json, false);
  }

  export function V1ListRequestQueuesResponseRequestQueueToJSONTyped(value?: V1ListRequestQueuesResponseRequestQueue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'index': value['index'],
        'reason': ListRequestQueuesResponseQueueReasonToJSON(value['reason']),
        'createAt': value['createAt'] == null ? undefined : ((value['createAt']).toISOString()),
    };
}

