/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const WorkerWorkerDetailStatus = {
    Unspecified: 'DETAIL_STATUS_UNSPECIFIED',
    InFlight: 'DETAIL_STATUS_IN_FLIGHT',
    Remove: 'DETAIL_STATUS_REMOVE',
    Rollin: 'DETAIL_STATUS_ROLLIN',
    Busy: 'DETAIL_STATUS_BUSY',
    Free: 'DETAIL_STATUS_FREE'
} as const;
export type WorkerWorkerDetailStatus = typeof WorkerWorkerDetailStatus[keyof typeof WorkerWorkerDetailStatus];


export function instanceOfWorkerWorkerDetailStatus(value: any): boolean {
    for (const key in WorkerWorkerDetailStatus) {
        if (Object.prototype.hasOwnProperty.call(WorkerWorkerDetailStatus, key)) {
            if (WorkerWorkerDetailStatus[key as keyof typeof WorkerWorkerDetailStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function WorkerWorkerDetailStatusFromJSON(json: any): WorkerWorkerDetailStatus {
    return WorkerWorkerDetailStatusFromJSONTyped(json, false);
}

export function WorkerWorkerDetailStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkerWorkerDetailStatus {
    return json as WorkerWorkerDetailStatus;
}

export function WorkerWorkerDetailStatusToJSON(value?: WorkerWorkerDetailStatus | null): any {
    return value as any;
}

export function WorkerWorkerDetailStatusToJSONTyped(value: any, ignoreDiscriminator: boolean): WorkerWorkerDetailStatus {
    return value as WorkerWorkerDetailStatus;
}

