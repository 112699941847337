/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const V1AppStatusV1Alpha1Status = {
    Unspecified: 'STATUS_UNSPECIFIED',
    Deployed: 'STATUS_DEPLOYED',
    Paused: 'STATUS_PAUSED',
    Terminating: 'STATUS_TERMINATING',
    Deleted: 'STATUS_DELETED'
} as const;
export type V1AppStatusV1Alpha1Status = typeof V1AppStatusV1Alpha1Status[keyof typeof V1AppStatusV1Alpha1Status];


export function instanceOfV1AppStatusV1Alpha1Status(value: any): boolean {
    for (const key in V1AppStatusV1Alpha1Status) {
        if (Object.prototype.hasOwnProperty.call(V1AppStatusV1Alpha1Status, key)) {
            if (V1AppStatusV1Alpha1Status[key as keyof typeof V1AppStatusV1Alpha1Status] === value) {
                return true;
            }
        }
    }
    return false;
}

export function V1AppStatusV1Alpha1StatusFromJSON(json: any): V1AppStatusV1Alpha1Status {
    return V1AppStatusV1Alpha1StatusFromJSONTyped(json, false);
}

export function V1AppStatusV1Alpha1StatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1AppStatusV1Alpha1Status {
    return json as V1AppStatusV1Alpha1Status;
}

export function V1AppStatusV1Alpha1StatusToJSON(value?: V1AppStatusV1Alpha1Status | null): any {
    return value as any;
}

export function V1AppStatusV1Alpha1StatusToJSONTyped(value: any, ignoreDiscriminator: boolean): V1AppStatusV1Alpha1Status {
    return value as V1AppStatusV1Alpha1Status;
}

