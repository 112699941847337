/* tslint:disable */
/* eslint-disable */
/**
 * everai/apps/v1/worker.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { V1Volume } from './V1Volume';
import {
    V1VolumeFromJSON,
    V1VolumeFromJSONTyped,
    V1VolumeToJSON,
    V1VolumeToJSONTyped,
} from './V1Volume';

/**
 * 
 * @export
 * @interface AppServiceSetupVolumesBody
 */
export interface AppServiceSetupVolumesBody {
    /**
     * 
     * @type {Array<V1Volume>}
     * @memberof AppServiceSetupVolumesBody
     */
    volumes: Array<V1Volume>;
}

/**
 * Check if a given object implements the AppServiceSetupVolumesBody interface.
 */
export function instanceOfAppServiceSetupVolumesBody(value: object): value is AppServiceSetupVolumesBody {
    if (!('volumes' in value) || value['volumes'] === undefined) return false;
    return true;
}

export function AppServiceSetupVolumesBodyFromJSON(json: any): AppServiceSetupVolumesBody {
    return AppServiceSetupVolumesBodyFromJSONTyped(json, false);
}

export function AppServiceSetupVolumesBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppServiceSetupVolumesBody {
    if (json == null) {
        return json;
    }
    return {
        
        'volumes': ((json['volumes'] as Array<any>).map(V1VolumeFromJSON)),
    };
}

  export function AppServiceSetupVolumesBodyToJSON(json: any): AppServiceSetupVolumesBody {
      return AppServiceSetupVolumesBodyToJSONTyped(json, false);
  }

  export function AppServiceSetupVolumesBodyToJSONTyped(value?: AppServiceSetupVolumesBody | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'volumes': ((value['volumes'] as Array<any>).map(V1VolumeToJSON)),
    };
}

